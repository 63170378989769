import { Link } from "react-router-dom";
import React, { useState }  from 'react';

import '../Navbar.css';

export default function Header() {

    const [isOpen, setIsOpen] = useState(false);
  
    // Function to toggle the mobile menu
    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };

  return (
    <>
      {/* TopNav-bar Start */}
      <div className="container-fluid Top_Nav  text-white d-none d-lg-flex">
        <div className="container py-3">
          <div className="d-flex ">
            <div className="ms-auto d-flex align-items-center">
              <div className="text-white ms-2 website-link">
                <i className="fa fa-phone-alt me-"></i>+91-181-5039476
              </div>
              <div className="vertical-border"></div>
              <Link
                className="text-white ms-2 email-link" // Add email-link class
                to="/"
                target="_blank"
              >
                <i className="fa fa-envelope me-1"></i>info@kaysonpaints.com
              </Link>
              <div className="vertical-border"></div>
              <Link
                className="text-white ms-2 website-link" // Add website-link class
                to="/"
                target="_blank"
              >
                www.kaysonpaints.com
              </Link>
              <div className="vertical-border"></div>
              <div className="ms-3 d-flex">
              <Link
                  className="btn btn-sm-square btn-light  rounded-circle ms-2"
                  to="https://wa.me/918968500476"
                  target="_blank"
                >
                  <i className="fab fa-whatsapp fa-lg"></i>
                </Link>
                <Link
                  className="btn btn-sm-square btn-light  rounded-circle ms-2"
                  to="https://www.facebook.com/kaysonpaints/"
                  target="_blank"
                >
                  <i className="fab fa-facebook fa-lg"></i>
                </Link>
                <Link
                  className="btn btn-sm-square btn-light  rounded-circle ms-2"
                  to="https://www.instagram.com/kaysonpaints/"
                  target="_blank"
                >
                  <i className="fab fa-instagram fa-lg"></i>
                </Link>
                <Link
                  className="btn btn-sm-square btn-light  rounded-circle ms-2"
                  to="/"
                  target="_blank"
                >
                  <i className="fab fa-youtube fa-lg"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="container-fluid bg-white">
  <div className="container">
    <nav className="navbar bg-white navbar-light p-lg-0">
      <img
        src="/Assets/img/Kayson_Logo_Final.jpg"
        className="imglogo"
        alt="kayson_Logo"
        style={{ height: '90px', width: '150px' }}
      />

      <button
        className={`hamburger ${isOpen ? 'open' : ''}`}
        onClick={toggleMenu}
      >
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </button>

      {/* Mobile Navigation */}
      <div className={`nav-menu ${isOpen ? 'open' : ''}`}>
        <div className="navbar-nav">
          <Link to="/" className="nav-item nav-link me-4" onClick={toggleMenu}>
            Home
          </Link>
          <Link to="/ourproducts" className="nav-item nav-link me-4" onClick={toggleMenu}>
            Products
          </Link>
          <Link to="/presence" className="nav-item nav-link me-4" onClick={toggleMenu}>
            Presence
          </Link>
          <Link to="/about" className="nav-item nav-link me-4" onClick={toggleMenu}>
            About
          </Link>
          <Link to="/contact" className="nav-item nav-link me-4" onClick={toggleMenu}>
            Contact
          </Link>
        </div>
      </div>

      <div className="navbar-nav desktop-nav">
        <Link to="/" className="nav-item nav-link me-4">
          Home
        </Link>
        <Link to="/ourproducts" className="nav-item nav-link me-4">
          Products
        </Link>
        <Link to="/presence" className="nav-item nav-link me-4">
          Presence
        </Link>
        <Link to="/about" className="nav-item nav-link me-4">
          About
        </Link>
        <Link to="/contact" className="nav-item nav-link me-4">
          Contact
        </Link>
      </div>
    </nav>
  </div>
</div>

      {/* NAvbar Ends */}
    </>
  )
}
