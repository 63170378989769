
import React from "react";
import { FaMedal, FaLightbulb, FaUsers, FaHandshake, FaCheckCircle, FaLeaf } from "react-icons/fa"; // Imported react-icons
import "./Whyus.css"
export default function Hero() {
  return (
    <div className="container-fluid px-5 my-5">
      <div className="row">

        <div className="col-md-5 d-flex align-items-center justify-content-center" >

          <h1 className="why-choose-us-heading">Why Choose Us?</h1>
        </div>
        <div className="col-md-7">
          <div className="bg-white p-4 rounded shadow-lg">
            <div className="row g-4">
              <div className="col-sm-6 d-flex align-items-start">
                <FaMedal size={50} className="icon-style" />
                <div className="ms-3">
                  <h5 className="heading-text">QUALITY FIRST</h5>
                  <p className="text-muted">
                    Commitment to delivering top-notch paint products with unwavering quality standards.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 d-flex align-items-start">
                <FaLightbulb size={50} className="icon-style" />
                <div className="ms-3">
                  <h5 className="heading-text">INNOVATION DRIVE</h5>
                  <p className="text-muted">
                    Foster a culture of continuous improvement and forward-thinking to stay ahead in the industry.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 d-flex align-items-start">
                <FaUsers size={60} className="icon-style" />
                <div className="ms-3">
                  <h5 className="heading-text">CUSTOMER FOCUS</h5>
                  <p className="text-muted">
                    Prioritize customer needs, providing exceptional products and services that exceed expectations.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 d-flex align-items-start">
                <FaHandshake size={60} className="icon-style" />
                <div className="ms-3">
                  <h5 className="heading-text">INTEGRITY MATTERS</h5>
                  <p className="text-muted">
                    Uphold the highest levels of honesty, transparency, and ethical conduct in all business interactions.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 d-flex align-items-start">
                <FaCheckCircle size={50} className="icon-style" />
                <div className="ms-3">
                  <h5 className="heading-text">RELIABILITY ASSURANCE</h5>
                  <p className="text-muted">
                    Maintain a track record of consistent and reliable product delivery, building trust with customers.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 d-flex align-items-start">
                <FaLeaf size={50} className="icon-style" />
                <div className="ms-3">
                  <h5 className="heading-text">ENVIRONMENTAL RESPONSIBILITY</h5>
                  <p className="text-muted">
                    Minimizing our environmental footprint by developing and promoting eco-friendly paint solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
